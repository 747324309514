const sendTelemetryLog = (url, logs) => {
  return fetch(url, {
    method: 'POST',
    body: JSON.stringify({ messages: logs }),
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export { sendTelemetryLog };